import React from 'react'
import styled from 'styled-components';
import { Box, Text } from 'rebass/styled-components'
import { Link } from 'gatsby'
import BrandmarkImage from '../../assets/icons/brandmark-static.svg'
import { connect } from 'react-redux'


const RestoText = styled(Text)`
    color: white;
    font-family: ${(props) => props.theme.fonts.oswald};
    font-weight: 400;
    font-size:${(props) => props.theme.fontSizes.xs};
    letter-spacing:4px;
    line-height:17px;
    left: calc(90% - 66px);
    top:140px;
    position: absolute;
    z-index: 999;
    width: 140px;
    text-align: center;
`

const BrandmarkAnchor = styled(Link)`
    display: block;
    height: 110px;
    left: calc(90% - 33px);
    position: absolute;
    top: -12px;
    width: 120px;
    z-index: 10;

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        height: 85px;
        padding-top: 2px;
        padding-bottom: 19px;
        margin:auto;
        left:0;
        right:0;
        top: -6px;
        width: 61px;
    }
`


const Brandmark = ({ withText, role, link } ) => {
    const WithText = (withText) ? <RestoText>RESTORATION PORTAL</RestoText> : null
    const route = (role === 'client') ? '/clients' : '/restorers'

    // Cannot use logo to bypass the login page.
    const welcome = (link === 'false') ? null : route+ '/welcome/'

    return (
        <Box sx={{ zIndex: '100' }}>
            <BrandmarkAnchor to={welcome} aria-label="Link to homepage">
                <Box mt='xs'>
                    <BrandmarkImage/>
                </Box>
            </BrandmarkAnchor>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        role: state.Auth.user.role,
    };
}

export default connect(mapStateToProps)(Brandmark);
