/* eslint-disable */
// TODO: extend use of the Helmet to accommodate for JSON+LD and SchemaOrg
// e.g.: https://github.com/jlengstorf/gatsby-theme-jason-blog/blob/master/src/components/SEO/SEO.js
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Meta = ({ location, title, description, image, article }) => {
    const liveSite = process.env.GATSBY_ENV === 'production' ? true : false

    const HelmetMeta = {
        title: 'Kidston Restoration Portal',
    }

    // NB: If you’re using gatsby-plugin-offline, you might notice that when opening a link in the background, the title doesn’t appear in the tab bar until switching to that tab.
    // This is an upstream issue with React Helmet; however, it can be worked around by passing the defer={false} prop into your Helmet component.
    return (
        <Helmet
            htmlAttributes={{
                lang: 'en',
            }}
            title={HelmetMeta.title}
            defer={false}
        >
            <title>{HelmetMeta.title}</title>
            {/* Title handling */}
            {HelmetMeta.title && (
                <meta content={HelmetMeta.title} itemProp='name' />
            )}
            <meta content={HelmetMeta.title} property='og:title' />
            <meta content={HelmetMeta.title} name='twitter:title' />

            <meta content={HelmetMeta.title} property='og:site_name' />
            {/* <meta property="fb:app_id" content="" /> */}

            {/* TODO: Make image height, width and MIME type dynamic */}
            <meta content='512' property='og:image:height' />
            <meta content='512' property='og:image:width' />
            <meta content='image/png' property='og:image:type' />

            {/* Generic Defaults */}
            <meta httpEquiv='cleartype' content='on' />
            <meta
                name='viewport'
                content='user-scalable=yes, width=device-width, height=device-height, initial-scale=1.0, maximum-scale=10.0, minimum-scale=0.5'
            />
            <meta name='robots' content='noindex, nofollow' />

            <meta name='googlebot' content='noindex,nofollow' />

            <meta name='coverage' content='Worldwide' />
            <meta name='distribution' content='Global' />

            <meta name='renderer' content='webkit|ie-comp|ie-stand' />

            <meta name='HandheldFriendly' content={true} />
            <meta name='MobileOptimized' content='width' />

            <link
                rel='sitemap'
                type='application/xml'
                title='Sitemap'
                href='/sitemap/sitemap-0.xml'
            />

            <meta httpEquiv='x-dns-prefetch-control' content='on' />

            {/* Mobile Meta */}
            <meta name='mobile-web-app-capable' content='yes' />
            <meta name='apple-mobile-web-app-capable' content='yes' />
            <meta name='application-name' content={HelmetMeta.title} />
            <meta
                name='apple-mobile-web-app-title'
                content={HelmetMeta.title}
            />
            <meta name='theme-color' content='#101010' />
            <meta name='msapplication-navbutton-color' content='#101010' />
            <meta
                name='apple-mobile-web-app-status-bar-style'
                content='black-translucent'
            />
            <meta name='msapplication-starturl' content='/' />

            <meta name='HandheldFriendly' content={true} />
            <meta name='MobileOptimized' content='width' />

            {/* Favicon handling */}

            <link rel='icon' type='image/svg+xml' href='/favicon.svg' />
            {/* generics */}
            <link
                type='image/png'
                rel='icon'
                href='/favicon-32x32.png'
                sizes='32x32'
            />
            <link
                type='image/png'
                rel='icon'
                href='/favicon-128x128.png'
                sizes='128x128'
            />
            <link
                type='image/png'
                rel='icon'
                href='/favicon-192x192.png'
                sizes='192x192'
            />
            {/* android */}
            <link
                type='image/png'
                rel='shortcut icon'
                href='/favicon-196x196.png'
                sizes='196x196'
            />
            {/* iOS */}
            <link
                type='image/png'
                rel='apple-touch-icon'
                href='/favicon-152x152.png'
                sizes='152x152'
            />
            <link
                type='image/png'
                rel='apple-touch-icon'
                href='/favicon-167x167.png'
                sizes='167x167'
            />
            <link
                type='image/png'
                rel='apple-touch-icon'
                href='/favicon-180x180.png'
                sizes='180x180'
            />

            {/* Startup images handling */}
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-1136x1136.png'
                media='screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-2436x2436.png'
                media='screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-1792x1792.png'
                media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-828x828.png'
                media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-1334x1334.png'
                media='screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-1242x1242.png'
                media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-2208x2208.png'
                media='screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-1125x1125.png'
                media='screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-1242x1242.png'
                media='screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-2732x2732.png'
                media='screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-2688x2688.png'
                media='screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-2224x2224.png'
                media='screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-750x750.png'
                media='screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-2048x2048.png'
                media='screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-2388x2388.png'
                media='screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-1668x1668.png'
                media='screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-640x640.png'
                media='screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-1668x1668.png'
                media='screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-2048x2048.png'
                media='screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)'
            />
            <link
                type='image/png'
                rel='apple-touch-startup-image'
                href='/apple-touch-startup-image-1536x1536.png'
                media='screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
            />
        </Helmet>
    )
}

Meta.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    article: PropTypes.bool,
}

Meta.defaultProps = {
    title: null,
    description: null,
    image: null,
    article: false,
}

export default Meta
