import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Flex, Text } from 'rebass/styled-components'
import { openUploadModal } from '../../system/redux/reducers/modal'
import { Link } from 'gatsby'
import { connect } from 'react-redux'


const LinkBox = styled(Flex)`
    align-items: center;
    justify-content:center;
    height:50px;
    border-bottom: 1px solid ${(props) => props.theme.colors.black};
`

function MobileNav({ vehicle, role, dispatch }) {
    const [menuIsOpen, setIsOpen] = useState(false)
    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!menuIsOpen)
    }
    const handleUpload = (e) => {
        e.preventDefault()
        dispatch(openUploadModal('gallery'))
    }
    const ExternalLink = styled(Box)`
a{
text-decoration: none;
    &:hover {
        font-weight: bold;
    }
    &:focus, &:visited, &:link, &:active {
        text-decoration: none;
        color: black;
    }
    }
`
    // Dont show secondary nav when on these pages
    let showSecondaryNav = null
    if (window.location.href.indexOf('welcome') > -1 || window.location.href.indexOf('allRestorer') > -1 || window.location.href.indexOf('account') > -1 || window.location.href.indexOf('add') > -1) {
        showSecondaryNav = false
    } else {
        showSecondaryNav = true
    }

    const route = (role === 'client') ? '/clients' : '/restorers'
    const dropdownLinks = <Box
        bg='aquaMedium'
        height='100vh'
        width='100vw'
        pt='xxl'
        px='md'
        sx={{
            position: 'absolute',
            left: '0',
            zIndex: '10',
        }}>
        {
            role === 'client' ?
            // Client
                <>
                    {vehicle.vid && showSecondaryNav?
                        <>
                            <LinkBox>
                                <Link to={route + '/dashboard/' + vehicle.vid}>
                                    < Text variant='body' color='black'>
                                        Dashboard
                                    </Text>
                                </Link>
                            </LinkBox>
                            <LinkBox>
                                <Link to={route + '/invoices/' + vehicle.vid}>
                                    <Text variant='body' color='black'>
                                        Invoices
                                    </Text>
                                </Link>
                            </LinkBox>
                            <LinkBox>
                                <Link to={route + '/quotes/' + vehicle.vid}>
                                    <Text variant='body' color='black'>
                                        Quotes
                                    </Text>
                                </Link>
                            </LinkBox>
                            <LinkBox>
                                <Link to={route + '/gallery/' + vehicle.vid}>
                                    <Text variant='body' color='black'>
                                        Gallery
                                    </Text>
                                </Link>
                            </LinkBox>
                            <LinkBox mb='lg'>
                                <Link to={route + '/overview/' + vehicle.vid}>
                                    <Text variant='body' color='black'>
                                        Overview
                                    </Text>
                                </Link>
                            </LinkBox>
                        </> :
                        null}
                    <LinkBox>
                        <Link to={route + '/welcome/'}>
                            <Text variant='body' color='black'>
                                Home
                            </Text>
                        </Link>
                    </LinkBox>
                    <LinkBox sx={{ borderBottom: '0px solid black' }}>
                        <Link to='/clients/allRestorer/'>
                            <Text variant='body' color='black'>
                                Restorers
                            </Text>
                        </Link>
                    </LinkBox>
                    <LinkBox>
                        <Link to='/clients/account/'>
                            <Text variant='body' color='black'>
                                Account
                            </Text>
                        </Link>
                    </LinkBox>
                    <LinkBox>
                        <ExternalLink>
                            <a href="mailto:info@kidston.com?subject=Restoration%20Portal%20Enquiry">Contact</a>
                        </ExternalLink>
                    </LinkBox>
                </>:

            // Restorers
                <>
                    {vehicle.vid && showSecondaryNav?
                        <>
                            <LinkBox>
                                <Link to={route + '/dashboard/' + vehicle.vid}>
                                    < Text variant='body' color='black'>
                                        Dashboard
                                    </Text>
                                </Link>
                            </LinkBox>
                            <LinkBox>
                                <Link to={route + '/invoices/' + vehicle.vid}>
                                    <Text variant='body' color='black'>
                                        Invoices
                                    </Text>
                                </Link>
                            </LinkBox>
                            <LinkBox>
                                <Link to={route + '/quotes/' + vehicle.vid}>
                                    <Text variant='body' color='black'>
                                        Quotes
                                    </Text>
                                </Link>
                            </LinkBox>
                            <LinkBox>
                                <Link to={route + '/gallery/' + vehicle.vid}>
                                    <Text variant='body' color='black'>
                                        Gallery
                                    </Text>
                                </Link>
                            </LinkBox>
                            <LinkBox sx={{ borderBottom: '0px solid black' }}>
                                <Link to='#' onClick={handleUpload}>
                                    <Text variant='body' color='black'>
                                        Upload
                                    </Text>
                                </Link>
                            </LinkBox>
                            <LinkBox mb='lg'>
                                <Link to={'/restorers/edit/' + vehicle.vidd}>
                                    <Text variant='body' color='black'>
                                        Edit
                                    </Text>
                                </Link>
                            </LinkBox>
                        </> :
                        null}
                    <LinkBox>
                        <Link to={route + '/welcome/'}>
                            <Text variant='body' color='black'>
                                Home
                            </Text>
                        </Link>
                    </LinkBox>
                    <LinkBox>
                        <Link to={route + '/account/'}>
                            <Text variant='body' color='black'>
                                Restorers
                            </Text>
                        </Link>
                    </LinkBox>
                    <LinkBox>
                        <Link to={route + '/add/'}>
                            <Text variant='body' color='black'>
                                Add New
                            </Text>
                        </Link>
                    </LinkBox>
                    <LinkBox>
                        <ExternalLink>
                            <a href="mailto:info@kidston.com?subject=Restoration%20Portal%20Enquiry">Contact Kidston</a>
                        </ExternalLink>
                    </LinkBox>
                </>
        }
    </Box>
    const dropdown = (menuIsOpen) ? dropdownLinks : null

    return (
        <>
            <Flex height='56px' alignItems='center' justifyContent='center'>
                <Link to='#' onClick={handleClick}>
                    <Text variant='body' color='black' >
                        Menu
                    </Text>
                </Link>
            </Flex>
            {dropdown}
        </>
    );
}

function mapStateToProps(state) {
    return {
        vehicle: state.Vehicle,
        role: state.Auth.user.role,

    };
}

export default connect(mapStateToProps)(MobileNav);
