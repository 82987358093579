import React, { useState, useEffect } from 'react'
import { Box } from 'rebass/styled-components'
import styled from 'styled-components'
import { Link } from 'gatsby'
import MobileNav from './MobileNav'
import { connect } from 'react-redux'
import { clearUserVehicle } from '../../system/redux/reducers/vehicle'
import { clearAccountDetails } from '../../system/redux/reducers/account'
import { clearUploadMedia } from '../../system/redux/reducers/upload'

const StyledLink = styled(Link)`
    text-decoration: none;
    &:hover {
        font-weight: bold;
    }
    &:focus, &:visited, &:link, &:active {
        text-decoration: none;
        color: black;
    }
`;

const ExternalLink = styled(Box)`
a{
text-decoration: none;
    &:hover {
        font-weight: bold;
    }
    &:focus, &:visited, &:link, &:active {
        text-decoration: none;
        color: black;
    }
    }
`

const Primary = ({ role, dispatch }) => {
    const isSSR = typeof window === 'undefined'
    const [type, setRole] = useState()
    // role clears on hard refresh
    useEffect(() => {
        let type = role
        const url = typeof window !== 'undefined' ? window.location.href : '';
        if (url.indexOf('clients') > -1) {
            type = 'client'
        }
        setRole(type)
    }, [])
    const handleClick = (e) => {
        dispatch(clearUserVehicle())
        dispatch(clearAccountDetails())
        dispatch(clearUploadMedia())
    }
    let output = null
    switch (type) {
    case 'client': output = <>
        <Box
            alignItems='center'
            display={['none', 'none', 'none', ' none', 'flex']}
            justifyContent='space-around'>
            <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']}>
                <StyledLink to='/clients/welcome/'>
                    Home
                </StyledLink>
            </Box>
            <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']}>
                <StyledLink to='/clients/allRestorer/'>
                    Restorers
                </StyledLink>
            </Box>
            <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']}>
                <StyledLink to='/clients/account/'>
                    Account
                </StyledLink>
            </Box>
            <ExternalLink mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']}>
                <a href='mailto:info@kidston.com?subject=Restoration%20Portal%20Enquiry'>
                    Contact
                </a>
            </ExternalLink>
        </Box>
        <Box mx='xs' display={['block', 'block', 'block', 'block', 'none']}>
            <MobileNav />
        </Box>
    </>

        break
    default: output = <>
        <Box
            alignItems='center'
            display={['none', 'none', 'none', ' none', 'flex']}
            justifyContent='space-around'>
            <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']}>
                <StyledLink to='/restorers/welcome/'>
                    Home
                </StyledLink>
            </Box>
            <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']}>
                <StyledLink to='/restorers/account/' onClick={(e) => handleClick(e)}>
                    Restorers
                </StyledLink>
            </Box>
            <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']}>
                <StyledLink to='/restorers/add/' onClick={(e) => handleClick(e)}>
                    Add New
                </StyledLink>
            </Box>
            <ExternalLink mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']}>
                <a href='mailto:info@kidston.com?subject=Restoration%20Portal%20Enquiry'>
                    Contact Kidston
                </a>
            </ExternalLink>
        </Box>
        <Box mx='xs' display={['block', 'block', 'block', 'block', 'none']}>
            <MobileNav />
        </Box>
    </>
        break
    }

    return !isSSR && (<>{output}
    </>
    )
}

function mapStateToProps(state) {
    return {
        role: state.Auth.user.role,
    };
}

export default connect(mapStateToProps)(Primary)
