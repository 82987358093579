import React from 'react';
import { Box } from 'rebass/styled-components'
import styled from 'styled-components'

const Lines = styled(Box)`
    background-color: ${(props) => props.theme.colors.aquaMedium};
    height:13px;
    width:100%;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
`
const Line =styled(Box)`
    background-color: ${(props) => props.theme.colors.aquaMedium};
    height:1px;
    width:100%;
`

function HeaderLines() {
    return (<>
        <Line />
        <Lines />
        <Line />
    </>);
}

export default HeaderLines;
