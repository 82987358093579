import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Flex, Box } from 'rebass/styled-components'
import { connect } from 'react-redux'
import { openUploadModal } from '../../system/redux/reducers/modal'


const StyledLink = styled(Link)`
    letter-spacing: 1.1px;
    text-decoration: none;
    font-size:11px;
    &:hover {
        font-weight: bold;
    }
    &:focus, &:visited, &:link, &:active {
        text-decoration: none;
        color: ${(props) => props.theme.colors.grey};
    }
`
const Secondary = ({ vehicle, role, dispatch }) => {
    const isSSR = typeof window === 'undefined'
    const route = (role === 'client') ? '/clients' : '/restorers'
    const handleUpload = (e) => {
        e.preventDefault()
        dispatch(openUploadModal('gallery'))
    }

    // Doing it like this to maintain the height when the links aren't displayed.
    let links = null
    if (role === 'client') {
        links =
            <Flex flexWrap='wrap' sx={{ zIndex: 2 }}>
                <Box mx='xxs' pt={['xxs', 'xxs', '0px']}>
                    <StyledLink to={route + '/dashboard/' + vehicle.vid}>
                        DASHBOARD
                    </StyledLink>
                </Box>
                <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']} pt={['xxs', 'xxs', '0px']}>
                    <StyledLink to={route + '/invoices/' + vehicle.vid}>
                        INVOICES
                    </StyledLink>
                </Box>
                <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']} pt={['xxs', 'xxs', '0px']}>
                    <StyledLink to={route + '/quotes/' + vehicle.vid}>
                        QUOTES
                    </StyledLink>
                </Box>
                <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']} pt={['xxs', 'xxs', '0px']}>
                    <StyledLink to={'/clients/gallery/' + vehicle.vid}>
                        GALLERY
                    </StyledLink>
                </Box>
                <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']} pt={['xxs', 'xxs', '0px']}>
                    <StyledLink to={'/clients/overview/' + vehicle.vid}>
                        OVERVIEW
                    </StyledLink>
                </Box>
            </Flex>
    } else {
        links =
            <Flex flexWrap='wrap' >
                <Box mx='xxs' pt={['xxs', 'xxs', '0px']} sx={{ zIndex: '99' }}>
                    <StyledLink to={route + '/dashboard/' + vehicle.vid}>
                        DASHBOARD
                    </StyledLink>
                </Box>
                <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']} pt={['xxs', 'xxs', '0px']} sx={{ zIndex: '99' }}>
                    <StyledLink to={route + '/invoices/' + vehicle.vid}>
                        INVOICES
                    </StyledLink>
                </Box>
                <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']} pt={['xxs', 'xxs', '0px']} sx={{ zIndex: '99' }}>
                    <StyledLink to={route + '/quotes/' + vehicle.vid}>
                        QUOTES
                    </StyledLink>
                </Box>
                <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']} pt={['xxs', 'xxs', '0px']} sx={{ zIndex: '99' }}>
                    <StyledLink to={route + '/gallery/' + vehicle.vid}>
                        GALLERY
                    </StyledLink>
                </Box>
                <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']} pt={['xxs', 'xxs', '0px']} sx={{ zIndex: '99' }}>
                    <StyledLink to='#' onClick={handleUpload}>
                        UPLOAD
                    </StyledLink>
                </Box>
                <Box mx={['xxs', 'xxs', 'xs', 'xs', 'xs', '4']} pt={['xxs', 'xxs', '0px']} sx={{ zIndex: '99' }}>
                    <StyledLink to={'/restorers/edit/' + vehicle.vid}>
                        EDIT
                    </StyledLink>
                </Box>
            </Flex>
    }
    // TODO: don't love this for handling the nav display.
    const url = typeof window !== 'undefined' ? window.location.href : '';
    let output = links;
    if (url.indexOf('welcome') > -1 || url.indexOf('account') > -1 || url.indexOf('add') > -1 || url.indexOf('allRestorer') > -1 || url.indexOf('terms') > -1 || url.indexOf('privacy') > -1) {
        output = null
    }


    return (
        <>
            {!isSSR && (
                <>
                    <Box height='40px' display={['block', 'block', 'block', 'block', 'none']}></Box>
                    <Box display={['none', 'none', 'none', 'none', 'flex']} mx={['0', '0', '0', 'mds']} mt={['lg,', 'lg', 'lg', 'lg', 'sm']} mb={['md', 'md', 'md', 'md', '90px']}>
                        {output}
                    </Box>
                </>
            )}
        </>
    )
}

function mapStateToProps(state) {
    return {
        vehicle: state.Vehicle,
        role: state.Auth.user.role,

    };
}

export default connect(mapStateToProps)(Secondary);

