import React from 'react'
import { Box, Text, Flex } from 'rebass/styled-components'
import Primary from '../components/nav/Primary'
import Secondary from '../components/nav/Secondary'
import Brandmark from '../components/general/Brandmark'
import HeaderLines from '../components/nav/HeaderLines'


const Header = ({ type }) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const colour = url.includes('restorers') ? 'aquaMedium' : 'black';


    let output = null

    switch (type) {
    case 'login':
        output = <Flex display= {['block', 'block', 'block', 'flex']} bg= {colour} height={['300px', '300px', '300px', '300px', '350px']} justifyContent='space-between' mb='sm' sx={{ position: 'relative' }}>
            <Box maxWidth='700px' overflow='hidden'>
                <Text as='h1' variant='bigText'>
                    RESTORATION PORTAL
                </Text>
            </Box>
            <Box width='100%'sx={{ position: 'absolute', mx: 'auto', left: '0', right: '0' }}>
                <Brandmark link='false'/>
            </Box>
        </Flex >
        break
    case 'home':
        output = <>
            <Flex bg= 'aquaMedium' height='56px' justifyContent='flex-end'>
                <Flex mr={['0', '0', 'md', 'md', 'md', 'md', '150px', '200px', '360px']}>
                    <Primary/>
                </Flex>
                <Brandmark withText='true' />
            </Flex>
            <HeaderLines />
        </>
        break
    case 'main':
        output = <Box>
            <Flex bg= 'aquaMedium' height='56px' justifyContent='flex-end'>
                <Flex mr={['0', '0', 'md', 'md', 'md', 'md', '150px', '200px', '360px']}>
                    <Primary/>
                </Flex>
                <Box mr={['0', '0', '0', '0', 'xxxl', 'xxxl', '0']}><Brandmark withText='true' /></Box>
            </Flex>
            <HeaderLines />
            <Secondary />
        </Box>
        break
    default:
            // Do nothing
    }

    return (
        output
    )
}

// export default connect()(Header)
export default Header
